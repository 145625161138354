import { useContext, useState, useEffect } from 'react';
import { ABeagleContext, PageContext } from '../../contexts';
import ManualPromoBar from '../PromoBar/ManualPromoBar';

const MembershipPromoBar = () => {
  const { adsDisabled, isSponsored, membershipAvailable } = useContext(PageContext);
  const { experiments, getExperimentValue } = useContext(ABeagleContext);
  const [promoData, setPromoData] = useState(null);
  const commonData = {
    className: 'membershipPromo',
    trackingData: {
      unit_type: 'feed',
      unit_name: 'splash',
      subunit_type: 'component',
      subunit_name: 'promo_bar'
    },
  };

  const data = {
    control: {
      ...commonData,
      title: '17 Signs You\'re Sick Of Seeing Ads On BuzzFeed',
      description: '',
      cta: {
        text: 'Go Ad Free!',
        url: '/member-center/signup/products?utm_source=buzzfeed&utm_medium=web&utm_campaign=hp_bar1',
      },
      trackingData: {
        ...commonData.trackingData,
        item_name: '17 Signs You\'re',
      }
    },
    variant_1: {
      ...commonData,
      title: 'All Buzz. No Ads.',
      description: 'Introducing BuzzFeed+ — and the ad-free scroll of your dreams',
      cta: {
        text: 'Go Ad Free!',
        url: '/member-center/signup/products?utm_source=buzzfeed&utm_medium=web&utm_campaign=hp_bar2',
      },
      trackingData: {
        ...commonData.trackingData,
        item_name: 'All Buzz. No',
      }
    },
    variant_2: {
     ...commonData,
      title: 'No ads, just vibes.',
      description: 'BuzzFeed+ is here. Scroll happy.',
      cta: {
        text: 'Go Ad Free!',
        url: '/member-center/signup/products?utm_source=buzzfeed&utm_medium=web&utm_campaign=hp_bar3',
      },
      trackingData: {
        ...commonData.trackingData,
        item_name: 'No ads, just',
      }
    }
  }

  useEffect(() => {
    if (experiments && experiments.loaded) {
      setPromoData(data[
        getExperimentValue('BF-13987-membership-promo', {
          rejectErrors: false,
        })
      ]);
    }
  }, [experiments, getExperimentValue]);

  if (adsDisabled || isSponsored || !membershipAvailable || !promoData) {
    return null;
  }
  return (
    <ManualPromoBar {...promoData} />
  )
}

export default MembershipPromoBar;
